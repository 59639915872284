import React, { FC, useState, ChangeEvent } from 'react';
import { Box, TextField } from '@mui/material';

interface Props {
  onChange: (...args: any[]) => any;
}

const DateInput: FC<Props> = (props: Props) => {
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [dateError, setDateError] = useState('');

  const changeYear = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setYear(event.target.value.substring(0, 4));
      setMonth('');
      setDay('');
      props.onChange(null);
    }
  };
  const changeMonth = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '') {
      setMonth(event.target.value.substring(0, 2));
    } else if (regex.test(event.target.value) && +event.target.value <= 12 && +event.target.value >= 0) {
      setMonth(('0' + event.target.value).slice(-2));
    }
    setDay('');
    props.onChange(null);
  };
  const changeDay = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '') {
      setDay(event.target.value.substring(0, 2));
      props.onChange(null);
      setDateError('Ingresa una fecha válida');
    } else if (regex.test(event.target.value)) {
      const date = new Date(`${year}-${month}-${('0' + event.target.value).slice(-2)}T12:00:00Z`);
      if (isNaN(date.getDate()) === false) {
        setDay(`0${date.getDate()}`.slice(-2));
        setMonth(`0${1 + date.getMonth()}`.slice(-2));
        props.onChange(date.toISOString());
        setDateError('');
      } else if (event.target.value === '0') {
        setDay('00');
        props.onChange(null);
        setDateError('Ingresa una fecha válida');
      }
    } else {
      setDateError('Ingresa una fecha válida');
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex-row', marginBottom: '0', paddingBottom: '0' }}>
        <TextField
          sx={{ width: '40%', marginTop: '2%' }}
          label="Año"
          variant="outlined"
          value={year}
          onChange={changeYear}
          inputProps={{ inputMode: 'numeric' }}
        />
        <TextField
          disabled={year === '' || +year < 1930 || +year > 2023}
          sx={{ width: '25%', marginTop: '2%', marginX: '5%' }}
          label="Mes"
          variant="outlined"
          value={month}
          onChange={changeMonth}
          inputProps={{ inputMode: 'numeric' }}
        />
        <TextField
          disabled={year === '' || month === '' || month === '00'}
          sx={{ width: '25%', marginTop: '2%' }}
          label="Dia"
          variant="outlined"
          value={day}
          onChange={changeDay}
          inputProps={{ inputMode: 'numeric' }}
        />
      </Box>
      <Box sx={{ marginTop: '0', paddingTop: '0', color: 'error.main' }}>
        <p>{dateError}</p>
      </Box>
    </Box>
  );
};

export default DateInput;
