import React, { FC } from 'react';
import { Box } from '@mui/material';
import Timer from '../components/OtherComponents/Timer';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import onBoarding1 from './../images/onBoarding1.png';

const Waiting: FC = () => {
  const { first_name } = useSelector((state: RootState) => state.user);
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        textAlign: 'left',
      }}>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <img src={onBoarding1} width={'100%'} height={'450px'} />
          <Box
            sx={{
              position: 'absolute',
              top: '10vh',
              marginX: '10vw',
              width: '70vw',
              maxWidth: '430px',
              maxHeight: '400px',
              color: 'white',
            }}>
            <h1 className="App-logo-text">Bienvenido {first_name},</h1>
            <h1 className="App-logo-text">Gracias por completar tu registro!</h1>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center', paddingX: '5vw' }}>
          <h4>Makana estará disponible en tu ubicación en los próximos días!</h4>
          <Timer />
        </Box>
      </Box>
    </Box>
  );
};

export default Waiting;
