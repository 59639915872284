import React, { FC, useState, ReactElement } from 'react';
import NordicFormIntro from '../NordicFormIntro';
import NordicFormBody from './NordicBody';
import NordicWeekScale2 from './NordicWeekScale2';
import NordicPartDetail3 from './NordicPartDetails3';
import NordicSubmit from './NordicSubmit';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@mui/material/MobileStepper';

const Nordic2: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [block, setBlock] = useState<string[]>([]);
  const [next, setNext] = useState(true);
  const activeNext = (e: boolean) => {
    setNext(e);
  };

  const state = useSelector((state: RootState) => state.nordicForm);
  const enableWeek = Object.keys(state.firstCheck).map((key) => (state.firstCheck[key] === false ? 0 : 1));
  const views: ReactElement[] = [
    <NordicFormIntro key="0fi" />,
    <NordicFormBody onChange={activeNext} key="0fc" name="firstCheck" tag="12 meses" />,
  ];
  let maxSteps = 2;
  if (enableWeek.includes(1) === true) {
    maxSteps += 1;
    views.splice(2, 0, <NordicFormBody onChange={activeNext} key="1wc" name="weekCheck" tag="7 dias" />);
    const enableScale = Object.keys(state.firstCheck).map((key) => (state.weekCheck[key] === false ? 0 : 1));
    if (enableScale.includes(1) === true) {
      maxSteps += 1;
      views.splice(3, 0, <NordicWeekScale2 key="2ws" />);
    }
  }
  if (state.firstCheck.espaldaBaja === true) {
    maxSteps += 1;
    views.push(
      <NordicPartDetail3 onChange={activeNext} key="3lbd" name="lowerBackDetails" tag="la parte baja de la espalda" />,
    );
  }
  if (state.firstCheck.cuello === true) {
    maxSteps += 1;
    views.push(<NordicPartDetail3 onChange={activeNext} key="4nd" name="neckDetails" tag="el cuello" />);
  }
  if (state.firstCheck.hombro === true) {
    maxSteps += 1;
    views.push(<NordicPartDetail3 onChange={activeNext} key="5sd" name="shouldersDetails" tag="el hombro" />);
  }
  if (state.firstCheck.brazoCodo === true) {
    maxSteps += 1;
    views.push(<NordicPartDetail3 onChange={activeNext} key="6ed" name="elbowsExtra" tag="el codo" />);
  }
  if (state.firstCheck.manoMuñeca === true) {
    maxSteps += 1;
    views.push(<NordicPartDetail3 onChange={activeNext} key="6ed" name="handsExtra" tag="la mano/muñeca" />);
  }
  if (state.firstCheck.espaldaAlta === true) {
    maxSteps += 1;
    views.push(
      <NordicPartDetail3 onChange={activeNext} key="7ubd" name="upperBackExtra" tag="la parte alta de la espalda" />,
    );
  }
  if (state.firstCheck.caderaPierna === true) {
    maxSteps += 1;
    views.push(<NordicPartDetail3 onChange={activeNext} key="8pl" name="legsExtra" tag="la cadera/pierna" />);
  }
  if (state.firstCheck.rodilla === true) {
    maxSteps += 1;
    views.push(<NordicPartDetail3 onChange={activeNext} key="9k" name="kneesExtra" tag="la rodilla" />);
  }
  if (state.firstCheck.tobilloPie === true) {
    maxSteps += 1;
    views.push(<NordicPartDetail3 onChange={activeNext} key="10af" name="anklesExtra" tag="el tobillo/pie" />);
  }
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (
      views[activeStep + 1].key === '3lbd' ||
      views[activeStep + 1].key === '4nd' ||
      views[activeStep + 1].key === '5sd'
    ) {
      if (block.includes(`${views[activeStep + 1].key}`) === true) {
        setNext(true);
      } else {
        setNext(false);
      }
    } else {
      setNext(true);
    }
    if (views[activeStep].key === '3lbd' || views[activeStep].key === '4nd' || views[activeStep].key === '5sd') {
      if (block.includes(`${views[activeStep].key}`) === false) {
        const newBlock = block;
        newBlock.push(`${views[activeStep].key}`);
        setBlock(newBlock);
      }
    }
  };

  const handleBack = () => {
    if (views[activeStep].key === '3lbd' || views[activeStep].key === '4nd' || views[activeStep].key === '5sd') {
      if (block.includes(`${views[activeStep].key}`) === false && next === true) {
        const newBlock = block;
        newBlock.push(`${views[activeStep].key}`);
        setBlock(newBlock);
      }
    }
    if (
      views[activeStep - 1].key !== '3lbd' &&
      views[activeStep - 1].key !== '4nd' &&
      views[activeStep - 1].key !== '5sd'
    ) {
      setNext(true);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  views.sort((a, b) => (a.props.key > b.props.key ? -1 : 1));

  return (
    <Box sx={{ display: 'block', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ overflowY: 'scroll', height: '90vh' }}>
        <SwipeableViews disabled index={activeStep}>
          {views}
        </SwipeableViews>
      </Box>
      <MobileStepper
        sx={{ background: 'transparent', width: '90vw', marginX: '5vw' }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          activeStep === maxSteps - 1 ? (
            <NordicSubmit disabled={next !== true} />
          ) : (
            <Button
              sx={{ fontWeight: 'bold', borderRadius: '50px' }}
              variant="contained"
              size="small"
              disabled={next !== true && activeStep > 0}
              onClick={handleNext}
              aria-label="next">
              Siguiente
            </Button>
          )
        }
        backButton={
          activeStep === 0 ? (
            <Button sx={{ fontWeight: 'bold', color: '#FFF' }} size="small" aria-label="back">
              Atrás
            </Button>
          ) : (
            <Button
              sx={{ fontWeight: 'bold' }}
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
              aria-label="back">
              Atrás
            </Button>
          )
        }
      />
    </Box>
  );
};

export default Nordic2;
