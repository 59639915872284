import React, { FC, useState, useEffect } from 'react';
import { Box } from '@mui/material';

const Timer: FC = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const startDate = Date.parse('2023-12-01T09:00:00.000Z');

  const getTime = () => {
    const time = startDate - Date.now();
    setDays(Math.floor(time / 1000 / 60 / 60 / 24));
    setHours(Math.floor((time / 1000 / 60 / 60) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ color: '#1b4ed8', textAlign: 'center' }}>
      <h1 className="App-logo-text">
        {('0' + days).slice(-2)} DÍAS {('0' + hours).slice(-2)}:{('0' + minutes).slice(-2)}:{('0' + seconds).slice(-2)}
      </h1>
    </Box>
  );
};

export default Timer;
